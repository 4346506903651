import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"

const Header = ({ data }) => (
  <header
    style={{
      background: `#7fcfd8`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h2 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {data.site.siteMetadata.title}
        </Link>
      </h2>
    </div>
  </header>
)

export default function MyHeader() {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => <Header data={data}  />}
    />
  )
}
